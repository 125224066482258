import React, { useState, useMemo } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import Layout from "./Layout";
import InputField from "../../../components/InputField";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../redux/student/auth/studentAuthSlice";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showValidation, setShowValidation] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const validation = useMemo(
    () => ({
      isUppercase: /[A-Z]/.test(credentials.password),
      isLowercase: /[a-z]/.test(credentials.password),
      hasNumber: /\d/.test(credentials.password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(credentials.password),
      isMinLength: credentials.password.length >= 8,
    }),
    [credentials.password]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(
        resetPassword({
          token: id,
          password: credentials.password,
          confirmPassword: credentials.confirmPassword,
        })
      );
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Password changed successfully!");
        setCredentials({ password: "", confirmPassword: "" });
        navigate("/");
      } else {
        toast.error("Password reset failed. Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const isSaveDisabled =
    !credentials.password ||
    !credentials.confirmPassword ||
    credentials.password !== credentials.confirmPassword ||
    !Object.values(validation).every(Boolean);

  return (
    <Layout>
      <form
        className="w-full max-w-sm mx-auto text-center"
        onSubmit={handleSubmit}
      >
        <img src="/images/logo.png" alt="logo" className="w-60 mx-auto mb-4" />
        <h2 className="font-bold text-2xl text-[#171717] mb-4">
          Reset Password
        </h2>

        <div className="relative mb-4">
          <InputField
            name="password"
            onChange={handleChange}
            value={credentials.password}
            type={showPassword.password ? "text" : "password"}
            placeholder="Password"
            onFocus={() => setShowValidation(true)}
            onBlur={() => setShowValidation(false)}
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
            onClick={() =>
              setShowPassword((prev) => ({ ...prev, password: !prev.password }))
            }
          >
            {showPassword.password ? (
              <LuEye className="text-gray-400 text-2xl" />
            ) : (
              <LuEyeOff className="text-gray-400 text-2xl" />
            )}
          </button>
        </div>

        {showValidation && (
          <div className="border rounded-md p-4 text-sm text-left mb-4">
            {Object.entries(validation).map(([key, isValid]) => (
              <p
                key={key}
                className={isValid ? "text-green-500" : "text-gray-400"}
              >
                {isValid ? "✓" : "✗"}{" "}
                {key
                  .replace("is", "")
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
              </p>
            ))}
          </div>
        )}

        <div className="relative mb-4">
          <InputField
            name="confirmPassword"
            onChange={handleChange}
            value={credentials.confirmPassword}
            type={showPassword.confirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
          />
          <button
            type="button"
            className="absolute right-3 top-1/2 transform -translate-y-1/2"
            onClick={() =>
              setShowPassword((prev) => ({
                ...prev,
                confirmPassword: !prev.confirmPassword,
              }))
            }
          >
            {showPassword.confirmPassword ? (
              <LuEye className="text-gray-400 text-2xl" />
            ) : (
              <LuEyeOff className="text-gray-400 text-2xl" />
            )}
          </button>
        </div>
        {credentials.password && credentials.confirmPassword && (
          <p
            className={`text-sm ${
              credentials.password === credentials.confirmPassword
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {credentials.password === credentials.confirmPassword
              ? "Passwords match!"
              : "Passwords do not match."}
          </p>
        )}

        <button
          type="submit"
          className="btn bg-accent text-white w-full mt-4 rounded-md shadow-md"
          disabled={isSaveDisabled || loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </form>
    </Layout>
  );
};

export default ResetPassword;
