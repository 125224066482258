import React from "react";
import Skeleton from "../../../loaders/Skeleton";
import { setCode } from "../../../../redux/student/test/studentTestSlice";

const Essay = ({ question, count, setAnswer, answer, loading }) => {
  const handleAnswerChange = (e, index) => {
    const { value } = e.target;
    setAnswer(value);

    // dispatch(setCode(value));
    //console.log(answer);
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        question &&
        question.Title && (
          <div className="flex bg-[#F8F8F9] rounded-lg justify-between">
            <div className="w-11/12 flex flex-col gap-2 p-5">
              <h2 className="flex  gap-3  text-base w-full font-semibold">
                <div>Q{count}.</div>
                <div dangerouslySetInnerHTML={{ __html: question.Title }}></div>
                {/* <div className="self-end">{question.QuestionLevel}</div> */}
              </h2>
              <div className="flex flex-col gap-4">
                <textarea
                  className="w-full h-full text-lg bg-gray-100 border-none focus:outline-none mb-3 rounded-lg focus:ring-0 placeholder-gray-400 resize-y mih-h-[45vh]"
                  placeholder="Enter Answer Here"
                  name="studentAnswer"
                  value={answer || ""}
                  onChange={(e) => handleAnswerChange(e)}
                ></textarea>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Essay;
