import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  setSubmitTest,
  setTimerTest,
  updateTimer,
} from "../../../../redux/student/test/studentTestSlice";
import { endTest } from "../../../../redux/student/test/thunks/assessment";
import ConfirmPoP from "../../../PopUps/ConfirmPopUp";
import {
  exitFullscreen,
  isFullscreen,
} from "../../../../util/requestFullScreen";

const TestHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { timeTaken, START_TEST_LOADING } = useSelector((state) => {
    return state.studentTest;
  });
  const testTimer = JSON.parse(localStorage.getItem("timer"));
  const [timer, setTimer] = React.useState(parseInt(testTimer) * 60);
  const [timerText, setTimerText] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [confirmText, setConfirmText] = useState("Are you sure you want to submit the test?")

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleExitFullscreen = () => {
    if (isFullscreen()) {
      exitFullscreen();
    }
    window.addEventListener("contextmenu", (event) => {
      // Prevent the default behavior of the right-click context menu
      event.preventDefault();
    });
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (timer <= 0) {
      // dispatch(sendResponse({ testId: id, response: answerIndex }));
      toast.error("Time's up!");
      dispatch(
        endTest({
          timeTaken: timeTaken,
          testId: id,
          isAdaptive: searchParams.get("isAdaptive"),
        })
      );
      handleExitFullscreen();
    }
  }, [timer]);

  useEffect(() => {
    const hours = Math.floor(timer / 3600);
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;
    setTimerText(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    );
    // dispatch(setTimerTest(timer));
  }, [timer]);

  const handlePopUp = () => {
    setShowPopUp(true);
  };

  const handleSubmitTest = async () => {
    // dispatch(setTimerTest(timer));

    // submit the question
    // dispatch(setSubmitTest(true)).then(()=>{
    //   dispatch(
    //   endTest({
    //     timeTaken: timeTaken,
    //     testId: id,
    //     isAdaptive: searchParams.get("isAdaptive"),
    //   })
    // ).then(() => {
    //   dispatch(setSubmitTest(false));
    //   handleExitFullscreen();
    //   setShowPopUp(false);
    // });
    // })

    setShowPopUp(false);
    await dispatch(setSubmitTest(true));
    // await dispatch(setSubmitTest(false));
    handleExitFullscreen();
  };

  // IF LEAVES THE PAGE END THE TEST
  useEffect(() => {
    // handleSubmitTest();
    // dispatch(setSubmitTest(true));
    // return () => {
    //   dispatch(
    //     endTest({
    //       timeTaken: timeTaken,
    //       testId: id,
    //       isAdaptive: searchParams.get("isAdaptive"),
    //     })
    //   );
    // };
    return () => {
      handleSubmitTest();
    };
  }, []);

  return (
    <div
      className="flex w-full mx-auto justify-between bg-secondary pt-1 pr-2 "
      style={{ marginTop: "4.1rem" }}
    >
      {/* --------------------------- POPUP ------------------------------ */}

      {showPopUp && (
        <ConfirmPoP
          title={confirmText}
          handleConfirm={handleSubmitTest}
          handleCancel={() => setShowPopUp(false)}
        />
      )}

      {/* --------------------------- POPUP ------------------------------ */}

      <div className="h-fit self-center">
        {/* <button className="flex self-center ml-2 rounded-lg  gap-2">
        <button
          onClick={() => navigate(-1)}
          className=" mr-3 self-center bg-white rounded-lg "
        >
          <FaChevronLeft className=" p-3  h-10 w-10 self-center " />
        </button>

        <div className="self-center">
          <h2 className="sm:text-xl  text-left font-bold self-center text-3xl font-dmSans  text-white ">
            Create Assessment
          </h2>
        </div>
      </button> */}
      </div>

      <div className=" rounded-md mx-2   h-12 flex my-2 font-dmSans ">
        <div className=" flex gap-2">
          {/* <button
          className="self-center w-32  justify-center flex text-blue-800 py-2 px-4 rounded-md font-bold gap-2 bg-white"
          // onClick={() => navigate(-1)}
        >
        <img src='/images/icons/CombinedShape.png' alt="" className="self-center w-6 h-6" />
        <p className="text-lg font-bold self-center text-[#E45B39] ">
          {timerText}
        
          </p>
        </button> */}

          {/* <button
            className="self-center w-24  justify-center flex text-blue-800 py-2 px-4 rounded-md font-bold gap-2 bg-white"
            onClick={() => {
              setConfirmText("End test without saving?");
              handlePopUp()
            }}
          >
            Cancel
          </button> */}
          <button
            className="self-center  justify-center flex bg-red-500 py-2 font-bold px-4 rounded-md gap-2 text-white"
            // onClick={() => navigate("/collage/test/preview")}
            onClick={handlePopUp}
          >
            End Test & Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestHeader;
