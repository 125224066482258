import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  LuUser,
  LuCalendar,
  LuClock,
  LuAlertCircle,
  LuRefreshCw,
  LuDownload,
  LuShare2,
  LuCheckCircle,
  LuBuilding2,
} from "react-icons/lu";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import axios from "axios";
import { CgLock } from "react-icons/cg";
import { CiLock } from "react-icons/ci";
import { FaAngleLeft } from "react-icons/fa";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export default function StudentQRCode() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [refreshing, setRefreshing] = useState(false);
  const { user } = useSelector((state) => state.studentAuth);
  const [attendance, setAttendance] = useState();

  async function getAttendance() {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/student/profile/attendance`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      setAttendance(res.data?.attendance);
    } catch (error) {}
  }

  useEffect(() => {
    getAttendance();
  }, []);

  const student = {
    id: "12345",
    name: "John Doe",
    email: "john.doe@example.com",
    course: "Computer Science",
    rollNumber: "CS2023-125",
    attendance: {
      present: 42,
      absent: 3,
      total: 45,
      percentage: 93.33,
    },
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const formattedDate = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const slotTimeMapping = {
    1: "9:00 AM - 11:00 AM",
    2: "11:30 AM - 2:00 PM",
    3: "3:00 PM - 5:00 PM",
  };

  const companies = user?.internxStudent?.Companies;
  return (
    <>
      <div className="flex gap-2 items-center mb-5">
        <button
          className="bg-white border self-center rounded-md p-2 hover:shadow-md transition-shadow duration-300 hover:border-gray-500"
          onClick={() => navigate(-1)}
        >
          <FaAngleLeft className="h-5 w-5" />
        </button>
      </div>

      <div className="min-h-screen bg-gray-50 flex flex-col">
        <main className="flex-1 py-4 max-w-md mx-auto w-full">
          <div className="bg-white rounded-lg shadow-md py-6 text-center">
            {companies?.map((company, index) => {
              return (
                <>
                  <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-md bg-blue-100 flex items-center justify-center">
                          <LuBuilding2 className="h-5 w-5 text-blue-600" />
                        </div>
                        <div>
                          <h3 className="text-lg font-bold text-gray-800">
                            {company?.companyId.basic.companyName}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      <CiLock className="h-4 w-4" />
                      <span> Slot {slotTimeMapping?.[company?.Slot]}</span>
                    </div>
                  </div>
                </>
              );
            })}

            {/* <div className="bg-white rounded-lg shadow-md p-6 mb-4">
              <h3 className="text-lg font-bold text-gray-800 mb-4 text-center">
                Your Attendance QR Code
              </h3>
              <div
                className={`relative mx-auto w-64 h-64 bg-white border-8 border-blue-100 rounded-lg flex items-center justify-center mb-4 ${
                  refreshing ? "opacity-50" : ""
                }`}
              >
                {refreshing ? (
                  <LuRefreshCw
                    size={40}
                    className="text-blue-600 animate-spin"
                  />
                ) : (
                  <QRCode
                    value={user?._id || "placeholder"}
                    size={200}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </div> */}
            <h3 className="text-lg font-bold text-gray-800 mb-4">
              Your Attendance QR Code
            </h3>
            <div
              className={`relative mx-auto w-64 h-64 bg-white border-8 border-blue-100 rounded-lg flex items-center justify-center mb-4 ${
                refreshing ? "opacity-50" : ""
              }`}
            >
              {refreshing ? (
                <LuRefreshCw size={40} className="text-blue-600 animate-spin" />
              ) : (
                <QRCode value={user?._id} size={200} />
              )}
            </div>
            {/* <div className="flex justify-center gap-4 hidden">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                <LuRefreshCw size={16} /> Refresh
              </button>
              <button
                className="px-4 py-2 border border-blue-600 text-blue-600 rounded-md hover:bg-blue-50"
                onClick={() => alert("QR Code downloaded")}
              >
                <LuDownload size={16} /> Save
              </button>
              <button
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                onClick={() => alert("QR Code shared")}
              >
                <LuShare2 size={16} /> Share
              </button>
            </div> */}
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h3 className="text-lg font-bold text-gray-800 mb-4">
              Today's Status
            </h3>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-yellow-100 flex items-center justify-center mr-3">
                  {attendance?.status === "present" ? (
                    <LuCheckCircle size={20} className="text-green-600" />
                  ) : (
                    <LuAlertCircle size={20} className="text-yellow-600" />
                  )}
                </div>
                <div>
                  <p className="font-medium">
                    {attendance?.marked ? "Marked" : "Not Marked"}
                  </p>
                  <p className="text-sm text-gray-500">
                    {" "}
                    {attendance?.status !== "present"
                      ? "Pending"
                      : attendance?.status}{" "}
                  </p>
                </div>
              </div>
              <button
                className="text-xs bg-blue-600 text-white px-3 py-1.5 rounded-md hover:bg-blue-700"
                // onClick={navigate("/student")}
              >
                Get Scanned
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
