import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
const ProfilePersonalPage = lazy(() => import("./ProfilePersonalPage.jsx"));
const ProfileEducationPage = lazy(() => import("./ProfileEducationPage.jsx"));

const ProfileFinal = lazy(() => import("./ProfileFinalPage.jsx"));
const Skills = lazy(() => import("./SkillsPage.jsx"));
const Portfolio = lazy(() => import("./Portfolio.jsx"));
const StudentQRCode = lazy(() => import("./ProfileQR.jsx"));

const ProfileRoutes = () => {
  // //console.log("user", user);
  return (
    <Route path="student/profile">
      <Route path="qr" element={<StudentQRCode />} />

      {/* <Route path="/student-qr" element={<StudentQRCode />} /> */}

      {/* <Route path="" element={
        user && user?.completedProfile ? <ProfileFinal /> : <ProfilePersonalPage />
      } /> */}
      <Route path="" element={<ProfileLayout />} />

      <Route path="final" element={<ProfileFinal />} />
      <Route path="skills" element={<Skills />} />
      <Route path="portfolio" element={<Portfolio />} />
      {/* <Route path="personal" element={<ProfilePersonalPage />}></Route> */}
      <Route path="education" element={<ProfileEducationPage />}></Route>
    </Route>
  );
};

export default ProfileRoutes;

export const ProfileLayout = () => {
  const { user } = useSelector((state) => state.studentAuth);

  console.log("User:", user, "completedProfile:", user?.completedProfile);
  // const isProfileComplete = user && user?.completedProfile;
  const isProfileComplete = true;

  return isProfileComplete ? <ProfileFinal /> : <ProfilePersonalPage />;
};
