import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { endTest } from '../../redux/student/test/thunks/assessment';
import toast from 'react-hot-toast';
import { useNetworkStatus } from '../../hooks/useNetworkStatus';

const ConnectionStatus = ({ testId, timeTaken }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [countdown, setCountdown] = useState(30);
  const [showWarning, setShowWarning] = useState(false);
  const [showEndingAnimation, setShowEndingAnimation] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkNetworkSpeed } = useNetworkStatus();

  // Network check effect
  useEffect(() => {
    const checkConnection = async () => {
      const hasGoodConnection = await checkNetworkSpeed();
      if (!hasGoodConnection && isOnline) {
        setShowWarning(true);
        // toast.error('Poor connection detected', {
        //   duration: 0,
        // });
      } else if (hasGoodConnection && showWarning) {
        setShowWarning(false);
        setCountdown(30);
        toast.success('Connection restored', {
          duration: 3000,
        });
      }
    };

    const intervalId = setInterval(checkConnection, 5000);
    return () => clearInterval(intervalId);
  }, [isOnline, showWarning]);

  // Countdown effect
  useEffect(() => {
    let timer;
    if (showWarning && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            setShowEndingAnimation(true);
            clearInterval(timer);
            // Handle test submission
            setTimeout(async () => {
              // await dispatch(endTest({ testId, timeTaken }));
              navigate('/student/test');
            }, 1000);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showWarning, countdown]);

  return (
    <>
      {/* Connection Warning */}
      {showWarning && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] flex items-center justify-center">
          <div className="w-full max-w-lg mx-4">
            {/* Warning Card */}
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="bg-red-500 p-4">
                <h2 className="text-2xl font-bold text-white text-center">Connection Lost!</h2>
              </div>

              {/* Content */}
              <div className="p-6 text-center">
                <div className="mb-6">
                  <p className="text-gray-700 text-lg">
                    Poor internet connection detected. Test will be automatically submitted in
                  </p>
                </div>

                {/* Countdown Timer */}
                <div className="relative w-32 h-32 mx-auto">
                  <div className="absolute inset-0">
                    <div className="w-full h-full rounded-full border-4 border-red-200 flex items-center justify-center">
                      <span className="text-4xl font-bold text-red-500">{countdown}</span>
                    </div>
                  </div>
                  <div className="absolute inset-0 animate-ping">
                    <div className="w-full h-full rounded-full border-4 border-red-500 opacity-25"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Test Ending Animation */}
      {showEndingAnimation && (
        <div className="fixed inset-0 bg-black/90 z-[10000] flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin mb-4">
              <div className="w-16 h-16 border-4 border-t-blued rounded-full"></div>
            </div>
            <h2 className="text-2xl font-bold text-white mb-2">Test Ending...</h2>
            <p className="text-gray-300">Please wait while we submit your responses</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectionStatus;