import React from 'react'

const SubmitTestLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="text-white text-2xl font-semibold animate-pulse">
        Submitting the test, please wait...
      </div>
    </div>
  )
}

export default SubmitTestLoader
