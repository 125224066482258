export const useNetworkStatus = () => {
  const checkNetworkSpeed = async () => {
    try {
      const startTime = performance.now();
      const response = await fetch('https://www.google.com/favicon.ico', {
        mode: 'no-cors',
        cache: 'no-cache'
      });
      const endTime = performance.now();
      const duration = endTime - startTime;
      return duration < 1000; // Consider connection good if response time < 1s
    } catch {
      return false;
    }
  };
  return { checkNetworkSpeed };
};