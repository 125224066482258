import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";

import { getAssessment } from "../../../student/dashboard/dashboardSlice";
import dataURItoBlob from "../../../../util/dataUriToBlob";
import { setCode } from "../studentTestSlice";

export const getAllAssessments = createAsyncThunk(
  "studentTest/getAllAssessments",
  async (data, { rejectWithValue }) => {
    //console.log(data.status);
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/student/test?status=${data?.status}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data.assessments;
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAssessmentById = createAsyncThunk(
  "studentTest/getAssessmentById",
  async (testId, { rejectWithValue }) => {
    //console.log(process.env.REACT_APP_API_URL);
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/student/test/${testId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data.assessment;
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendResponse = createAsyncThunk(
  "studentTest/sendResponse",
  async (data, { rejectWithValue }) => {
    const authToken = localStorage.getItem("auth-token");
    //console.log(data, "data");
    if (!authToken) {
      return rejectWithValue("Auth token not found");
    }

    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/response/${data.testId}`,
        { response: data.response || -1, timeTaken: data.timeTaken || 0 }, // Send response data in the request body
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendResponseNonAdaptive = createAsyncThunk(
  "studentTest/sendResponseNonAdaptive",
  async (data, { rejectWithValue }) => {
    const authToken = localStorage.getItem("auth-token");
    if (!authToken) {
      return rejectWithValue("Auth token not found");
    }

    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/response/non-adaptive/${data.testId}`,
        { response: data.response }, // Send response data in the request body
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const startTest = createAsyncThunk(
  "studentTest/startTest",
  async ({ testId, isAdaptive }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/start/${testId}?adaptive=${isAdaptive}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      if (res.success === false) {
        return rejectWithValue(res.message);
      }
      return { ...res, testId, isAdaptive };

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// ----------------------------------------- END TEST -----------------------------------------
// endType: {
//   type: {
//     type: String,
//     enum: ["auto", "manual"],
//     default: "auto",
//   },
//   description: {
//     type: String,
//     default: "Network issue",
//   }
// },
export const endTest = createAsyncThunk(
  "studentTest/endTest",
  async ({ testId, isAdaptive, endType }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/end/${testId}?adaptive=${isAdaptive}`,
        {
          timeTaken: JSON.parse(localStorage.getItem("timeTaken")), endType
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      // if (res.success === false) {
      //   return rejectWithValue(res.message);
      // }
      localStorage.removeItem("timeTaken");
      return res;

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addLog = createAsyncThunk(
  "studentTest/addLog",
  async ({ testId, data }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/compiler/log/${testId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLog = createAsyncThunk(
  "studentTest/addLog",
  async ({ testId, question }, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/compiler/log/${testId}?question=${question}`,
        {
          // params: {
          //   question: question,
          // },
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadScreenshot = createAsyncThunk(
  "studentTest/uploadScreenshot",
  async ({ assessmentId, base64Image }, { rejectWithValue }) => {
    try {

      const formData = new FormData();
      console.log(base64Image)
      formData.append('screenshot', dataURItoBlob(base64Image));
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/student/test/${assessmentId}/screenshot`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "auth-token": localStorage.getItem("auth-token"),
        },
      });

      const screenshotLog = response.data;

      return screenshotLog
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data.message);
    }
  }
);

const handleUserLang = (lang) => {
  if (lang === "Python" || lang === "Python3" || lang === "python") {
    return "py";
  } else if (lang === "C" || lang === "c") {
    return "c";
  } else if (lang === "Cpp" || lang === "C++" || lang === "cpp") {
    return "cpp";
  } else if (lang === "Java" || lang === "java") {
    return "java";
  }
};

export const compileCode = createAsyncThunk(
  "studentTest/compile",
  async ({ answer, question, userLang }, { dispatch, rejectWithValue }) => {
    try {


      const language = handleUserLang(userLang);
      // console.log(answer)

      const response = await axios.post(
        `${process.env.REACT_APP_COMPILER_ROUTE}/test`,
        {
          code: answer?.code[userLang]?.answerCode,
          language: language,
          compilerId: question._id,
        },
        {
          timeout: 70000,
        }
      );

      // console.log(response, "response")

      const { passed, failed } = response.data;
      const output = [];

      // Process passed test cases
      passed.forEach((testCase) => {
        output.push({
          ...testCase,
          output: testCase.realtimeOutputEscapeRemoved,
        });
      });

      // Process failed test cases
      failed.forEach((testCase) => {
        output.push({
          ...testCase,
          output: testCase.realtimeOutputEscapeRemoved,
        });
      });

      // If there are failed test cases, set the first non-hidden case log
      if (failed.length > 0) {
        const firstVisibleError = failed.find((testCase) => !testCase.isHidden);
        if (firstVisibleError) {
          dispatch(setCode({ log: firstVisibleError.error }));
        }
      }

      if (!output || output.length < 1) {
        // Handle no output scenario
        const test = question.testcase.map((testcase) => ({
          ...testcase,
          passed: false,
          output: "error",
        }));

        const updatedAnswer = {
          ...answer,
          testcase: test,
          codeLanguage: userLang,
          totalTestCases: 0,
        };

        dispatch(setCode(updatedAnswer));
        return updatedAnswer;
      }

      // Update answer state with passed and failed test cases
      const updatedAnswer = {
        ...answer,
        testcase: output,
        codeLanguage: userLang,
        totalTestCases: passed.length,
      };

      dispatch(setCode(updatedAnswer));


      return updatedAnswer; // Return response for potential use
    } catch (error) {
      // Handle errors
      const updatedAnswer = {
        ...answer,
        totalTestCases: 0,
      };

      dispatch(setCode(updatedAnswer));

      // dispatch(setCode({ log: "Code execution error" }));
      console.log(answer)
      return updatedAnswer;
    }
  }
);


