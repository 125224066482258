import React, { useState } from "react"
import { useNavigate } from "react-router-dom";

const NoSwitchingAnimation = () => (
  <div className="relative w-32 h-32 mx-auto">
    <svg className="w-full h-full" viewBox="0 0 100 100">
      <g className="animate-[shake_0.5s_ease-in-out_infinite]">
        <rect x="20" y="20" width="60" height="40" fill="#e5e7eb" rx="4" />
        <line x1="20" y1="30" x2="80" y2="30" stroke="#9ca3af" strokeWidth="2" />
        <circle cx="25" cy="25" r="2" fill="#9ca3af" />
      </g>
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-24 h-1 bg-red-500 rotate-45 animate-[grow_2s_ease-in-out_infinite]"></div>
    </div>
  </div>
)

const StayInFrameAnimation = () => (
  <div className="relative w-32 h-32 mx-auto">
    <svg className="w-full h-full" viewBox="0 0 100 100">
      <rect x="10" y="10" width="80" height="60" fill="none" stroke="#9ca3af" strokeWidth="2" rx="4" />
      <g className="animate-[bounce_2s_ease-in-out_infinite]">
        <circle cx="50" cy="40" r="15" fill="#60a5fa" />
        <circle cx="45" cy="35" r="3" fill="white" />
        <path d="M 45 45 Q 50 50 55 45" stroke="white" fill="none" />
      </g>
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-24 h-24 border-4 border-green-500 rounded-full animate-[pulse_2s_ease-in-out_infinite]"></div>
    </div>
  </div>
)

const TimerAnimation = () => (
  <div className="relative w-32 h-32 mx-auto">
    <svg className="w-full h-full" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="45" fill="none" stroke="#e5e7eb" strokeWidth="8" />
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="#60a5fa"
        strokeWidth="8"
        strokeDasharray="283"
        strokeDashoffset="283"
        className="animate-[countdown_10s_linear_infinite]"
      />
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="text-xl font-bold text-gray-800 animate-[fadeInOut_2s_ease-in-out_infinite]">Timer</div>
    </div>
  </div>
)

const TestInstructionsModal = ({ onClose, onStart }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const steps = [
    {
      title: "Before Starting",
      instructions: [
        {
          text: "Ensure stable internet connection",
          icon: "🌐",
          description: "Minimum 40 Mbps speed required",
        },
        {
          text: "Allow camera access",
          icon: "📷",
          description: "Your face must be clearly visible",
        },
        {
          text: "Allow Fullscreen mode",
          icon: "⤢",
          description: "Exit will be counted as violation",
        },
      ],
      illustration: <StayInFrameAnimation />,
    },
    {
      title: "During Test",
      instructions: [
        {
          text: "No tab switching allowed",
          icon: "⚠️",
          description: "Auto-submission on violation",
        },
        {
          text: "Stay within camera frame",
          icon: "👤",
          description: "Moving away is not allowed",
        },
        {
          text: "Watch your time limit",
          icon: "⏱️",
          description: "Auto-submits when time ends",
        },
      ],
      illustration: <NoSwitchingAnimation />,
    },
    {
      title: "Important Rules",
      instructions: [
        {
          text: "Auto-submission on violations",
          icon: "⚡",
          description: "3 warnings then auto-submit",
        },
        {
          text: "No external assistance",
          icon: "❌",
          description: "Work independently",
        },
        {
          text: "No question switching",
          icon: "🔒",
          description: "Complete current before next",
        },
      ],
      illustration: <TimerAnimation />,
    },
  ]

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-3xl mx-4 transform transition-all animate-[fadeIn_0.5s_ease-out]">
        {/* Add Close Button */}
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
          aria-label="Close"
        >
          <svg
            className="w-6 h-6 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="h-2 bg-gray-200 rounded-t-xl">
          <div
            className="h-full bg-blued rounded-tl-xl transition-all duration-500 ease-out"
            style={{ width: `${(currentStep / 3) * 100}%` }}
          />
        </div>

        <div className="p-8">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 animate-[slideDown_0.5s_ease-out]">
            {steps[currentStep - 1].title}
          </h2>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              {steps[currentStep - 1].instructions.map((instruction, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors animate-[fadeIn_0.5s_ease-out]"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <span className="text-2xl">{instruction.icon}</span>
                  <div>
                    <p className="font-medium text-gray-800">{instruction.text}</p>
                    <p className="text-sm text-gray-600">{instruction.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="flex items-center justify-center animate-[fadeIn_0.5s_ease-out]"
              style={{ animationDelay: "0.3s" }}
            >
              {steps[currentStep - 1].illustration}
            </div>
          </div>
        </div>

        <div className="p-6 border-t flex justify-between items-center bg-gray-50 rounded-b-xl">
          <button
            onClick={() => setCurrentStep((prev) => prev - 1)}
            disabled={currentStep === 1}
            className="btn btn-outline btn-sm animate-[fadeIn_0.5s_ease-out]"
          >
            Previous
          </button>

          <div className="flex space-x-2">
            {[1, 2, 3].map((step) => (
              <div
                key={step}
                className={`w-2.5 h-2.5 rounded-full transition-colors ${
                  currentStep >= step ? "bg-blued" : "bg-gray-300"
                }`}
              />
            ))}
          </div>

          {currentStep === 3 ? (
            <button onClick={onStart} className="btn bg-blued text-white btn-sm animate-[pulse_2s_infinite] 
            hover:scale-105 hover:bg-secondary">
           Continue to Test
            </button>
          ) : (
            <button
              onClick={() => setCurrentStep((prev) => prev + 1)}
              className="btn bg-blued text-white btn-sm animate-[fadeIn_0.5s_ease-out]  hover:scale-105 hover:bg-secondary"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestInstructionsModal
