import StudentTestLayout from "../../../layout/StudentTest";
import useTranslate from "../../../hooks/useTranslate";
import { getStudent } from "../../../redux/student/auth/studentAuthSlice";
import React, { useEffect, useRef, useState } from "react";
import Questions from "../../../components/student/test/start/Questions";
import { useDispatch, useSelector } from "react-redux";
import toast, { ToastBar } from "react-hot-toast";
import {
  sendResponse,
  sendResponseNonAdaptive,
  endTest,
  getLog,
  compileCode,
} from "../../../redux/student/test/thunks/assessment";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import QuestionsNonAdaptive from "../../../components/student/test/start/QuestionsNonA";
import FindAns from "../../../components/student/test/start/FindAns";
import Essay from "../../../components/student/test/start/Essay";
import Video from "../../../components/student/test/start/Video";
import Loader from "../../../components/loaders/Loader";
import Code from "../../../components/student/test/start/Code";
import FullscreenPrompt from "../../../components/PopUps/FullScreenPopUp";
import {
  requestFullscreen,
  isFullscreen,
  exitFullscreen,
} from "../../../util/requestFullScreen";
import ConfirmPoP from "../../../components/PopUps/ConfirmPopUp";
import {
  setTimerTest,
  updateTimer,
  setSubmitTest,
  setCode,
  setCodeLang,
} from "../../../redux/student/test/studentTestSlice";
import SubmitTestLoader from "../../../components/loaders/SubmitTestLoader";
import { BiReset } from "react-icons/bi";
import ConnectionStatus from '../../../components/ConnectionStatus/ConnectionStatus';

const TestHome = () => {
  // useTranslate();
  useEffect(() => {
    dispatch(getStudent());
  }, []);

  const [logsState, setLogs] = useState([]);
  const [submission, setSubmission] = useState(false);
  const [runTestLoading, setRunTestLoading] = useState(false);

  const [userCode, setUserCode] = useState(``);
  const [numberCount, setNumberCount] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, _] = useSearchParams();
  const isAdaptive = query.get("isAdaptive");
  const { id } = useParams();
  const { question, topic, count, topicName, isLast } = useSelector((state) => {
    return state.studentTest.adaptive;
  });

  const {
    nonAdaptive,
    timeTaken,
    NEXT_QUESTION_LOADING,
    TEST_COMPLETED,
    submitTest,
    code
  } = useSelector((state) => {
    return state.studentTest;
  });
  const [userLang, setUserLang] = useState(
    nonAdaptive?.question?.code?.Python?.defaultCode
      ? "Python"
      : nonAdaptive?.question?.code?.C?.defaultCode
        ? "C"
        : nonAdaptive?.question?.code?.Cpp?.defaultCode
          ? "Cpp"
          : nonAdaptive?.question?.code?.Java?.defaultCode
            ? "Java"
            : "Python"
  );
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showFullscreenPrompt, setShowFullscreenPrompt] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [movedTab, setMovedTab] = useState(0);
  const [timer, setTimer] = useState(parseInt(question?.Duration) * 60); // Initial time in seconds (59 minutes and 33 seconds)
  const [timerText, setTimerText] = useState("");
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [findAns, setFindAnswer] = useState([]);
  const [essay, setEssay] = useState("");
  const [video, setVideo] = useState({
    long: [""],
    short: [""],
    mcq: [-1],
  });
  const [coding, setCoding] = useState({
    code: {
      Java: {
        // defaultCode : "",
        answerCode: "",
      },
      Python: {
        // defaultCode : "",
        answerCode: "",
      },
      C: {
        // defaultCode : "",
        answerCode: "",
      },
      Cpp: {
        // defaultCode : "",
        answerCode: "",
      },
    },
    codeLanguage: "",
    totalTestCases: 0,
    totalPassedTestCases: 0,
    testcase: [],
  });
  const [isCompiled, setIsCompiled] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [autoSubmit, setAutoSubmit] = useState(false)
  const tabSwitchCountRef = useRef(0);
  const [userInput, setUserInput] = useState((prev) => {
    try {
      if (nonAdaptive?.question?.testcase?.length > 0 && !prev) {
        return nonAdaptive?.question.testcase[0].input;
      }
      return prev;
    } catch (error) {
      return prev;
    }
  });
  function formatType(type) {
    if (type === "findAnswer") {
      return "Find Answer";
    } else {
      return type;
    }
  }
  const handleEnterFullscreen = () => {
    if (!isFullscreen()) {
      requestFullscreen(document.documentElement);
    }
    setShowFullscreenPrompt(false);
    // disableKeyboardShortcuts();
    window.addEventListener("contextmenu", (event) => {
      // Prevent the default behavior of the right-click context menu
      event.preventDefault();
    });

    disableKeyboardShortcuts();
  };

  const handleCancelFullscreen = async () => {
    try {
      console.log("fullscreen exited");
      setShowFullscreenPrompt(false);
      dispatch(endTest({
        testId: id, timeTaken: 0, endType: {
          type: "auto",
          description: "User exited fullscreen mode",
        }
      }));
      navigate(`/test/start/end/${id}`);
    } catch (error) {
      console.log(error, "error")
    }
  };
  const disableKeyboardShortcuts = () => {
    window.addEventListener("keydown", (event) => {
      // Check if the key combination is a known keyboard shortcut
      if (isKeyboardShortcut(event)) {
        // Prevent the default behavior of the keyboard shortcut
        event.preventDefault();
      }
    });
  };
  const isKeyboardShortcut = (event) => {
    // Define an array of known keyboard shortcuts
    const shortcuts = [
      // { key: 'a', ctrl: true },
      // { key: 's', ctrl: true },
      // { key: 'z', ctrl: true },
      // Add more keyboard shortcuts as needed
    ];

    // Check if the event matches any of the known keyboard shortcuts
    return shortcuts.some((shortcut) => {
      return (
        event.key === shortcut.key &&
        event.ctrlKey === shortcut.ctrl &&
        event.altKey === shortcut.alt &&
        event.shiftKey === shortcut.shift
      );
    });
  };

  // useEffect(() => {

  //   const handleFullscreenChange = () => {
  //     if (!document.fullscreenElement) {
  //      //console.log("fullscreen exited");
  //      handleFullscreenChange();
  //     }
  //   };

  //   document.addEventListener('fullscreenchange', handleFullscreenChange);

  //   return () => {
  //     document.removeEventListener('fullscreenchange', handleFullscreenChange);
  //   };
  // }, []);

  useEffect(() => {
    setShowFullscreenPrompt(true);

    if (!TEST_COMPLETED) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (TEST_COMPLETED) {
      // toast.success("Test Completed");
    }
  }, [TEST_COMPLETED]);

  // -------------------------------------------------- handle response type --------------------------------------------
  const handleResponse = (type) => {
    let response;

    if (type === "submit" || type === "timeout") {
      if (nonAdaptive.question.Options) {
        if (
          (answerIndex < 0 ||
            answerIndex > 3 ||
            answerIndex === "" ||
            answerIndex === null) &&
          type !== "timeout"
        ) {
          // //console.log("response mcq err", response);
          toast.error("please select an answer");
          return;
        }
        response = answerIndex;
      } else if (
        nonAdaptive.question.questions &&
        nonAdaptive?.question.Title &&
        !nonAdaptive.question.Options &&
        !nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.videoFile
      ) {
        response = findAns;
      } else if (
        nonAdaptive.question.Title &&
        !nonAdaptive.question.Options &&
        !nonAdaptive.question.questions &&
        !nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.videoFile
      ) {
        response = essay;
      } else if (nonAdaptive.question.codeQuestion) {
        // //console.log("coding", coding);
        //handleRunCode()
        response = coding;
        // Handle(compiler)
      } else {
        response = video;
        // Handle video question
      }
    } else {
      if (
        nonAdaptive?.question?.Options &&
        !nonAdaptive?.question?.questions &&
        !nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.videoFile
      ) {
        response = -1;
      } else if (
        nonAdaptive?.question?.questions &&
        nonAdaptive?.question.Title &&
        !nonAdaptive.question.Options &&
        !nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.videoFile
      ) {
        response = nonAdaptive.question.questions.map(() => ({
          studentAnswer: "",
        }));
      } else if (
        nonAdaptive.question.Title &&
        !nonAdaptive.question.Options &&
        !nonAdaptive.question.questions &&
        !nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.videoFile
      ) {
        response = "";
      } else if (
        nonAdaptive.question.codeQuestion &&
        !nonAdaptive.question.Options &&
        !nonAdaptive.question.questions &&
        !nonAdaptive.question.videoFile
      ) {
        response = coding;
        // Handle(compiler)
      } else {
        // Handle video question
        response = video;
      }
    }
    // //console.log("response1234", response);
    return response;
  };

  // ------------------------------------------------------ TIMER ------------------------------------------------

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      tabSwitchCountRef.current += 1;
      console.log(tabSwitchCountRef.current >=
        (JSON.parse(localStorage.getItem("conf"))?.maxTabSwitches ||
          5), JSON.parse(localStorage.getItem("conf"))?.maxTabSwitches)
      if (
        tabSwitchCountRef.current >=
        (JSON.parse(localStorage.getItem("conf"))?.maxTabSwitches ||
          5)
      ) {
        exitFullscreen();
        toast.error(
          `You have switched tabs ${JSON.parse(localStorage.getItem("conf"))?.maxTabSwitches || 5
          } times. Ending the test.`
        );
        dispatch(endTest({
          timeTaken, testId: id, isAdaptive, endType: {
            type: "auto",
            description: "Switched tabs more than the allowed limit",
          }
        }));
        navigate(`/test/start/end/${id}`);
      } else {
        toast.error("You are not allowed to switch tabs");
      }
    }
  };

  const handleBeforeUnload = (e) => {
    // Show a warning message to the user
    e.preventDefault();
    e.returnValue = ""; // Some browsers require this to show the warning dialog
    setShowPopUp(true);
    // Dispatch the end test action
    // dispatch(endTest({ timeTaken, testId: id, isAdaptive }));
    // toast.error("Test Ended");
    // navigate("/student/test");
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          localStorage.setItem(
            "question",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("question")),
              Duration: String((prevTimer - 1) / 60),
            })
          );
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (timer <= 0) {
      toast.error("Time's up!");

      if (isAdaptive === "true") {
        dispatch(
          sendResponse({
            testId: id,
            response: answerIndex,
            timeTaken: timeTaken,
          })
        ).then((res) => {
          setAnswerIndex(-1);
          setNumberCount((prev) => prev + 1);
        });
        //   .then((res) => {
        //     // if (!res.data.nextQuestion) {
        //     //   // setSubmit(true);
        //     //   navigate("/student/test");
        //     // }
        //   }
        // )

        //todo : check if its a coding question

      } else {
        const res = handleResponse("timeout");
        // //console.log("response", res);

        if (nonAdaptive.question.codeQuestion && isCompiled) {
          setAutoSubmit(true);
          return;
        }
        dispatch(
          sendResponseNonAdaptive({
            testId: id,
            response: res,
            timeTaken: timeTaken,
          })
        ).then((res) => {
          setAnswerIndex(-1);
          setFindAnswer([]);
          setLogs([]);
          setEssay("");
          setVideo({
            long: [""],
            short: [""],
            mcq: [-1],
          });
        });
        //     .then((res) => {
        //       // dispatch(endTest({timeTaken: timeTaken,testId: id, isAdaptive: searchParams.get("isAdaptive")}));
        //       // if (!res.data.nextQuestion) {
        //       //   navigate("/student/test");
        //       //   // toast.success("Test Submitted");
        //       //   // setSubmit(true);

        //       // }
        // })
      }
    }
  }, [timer]);

  useEffect(() => {
    const hours = Math.floor(timer / 3600);
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;
    if (!showFullscreenPrompt) {
      setTimerText(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    } else {
      setTimer(parseInt(question?.Duration * 60));
    }
  }, [timer, showFullscreenPrompt]);

  useEffect(() => {
    let intervalId;
    if (showFullscreenPrompt) {
      dispatch(setTimerTest());
      intervalId = setInterval(() => {
        dispatch(updateTimer());
        // //console.log("timer" , timeTaken);
      }, 1000);
      // //console.log(
      //   "intervalId",
      //   intervalId,
      //   "timeTaken",
      //   timeTaken,
      //   showFullscreenPrompt
      // );
    } else {
      clearInterval(intervalId);
    }
  }, [showFullscreenPrompt]);

  useEffect(() => {
    if (isAdaptive === "true") {
      setTimer(parseInt(question?.Duration) * 60);
    } else {
      dispatch(setCode(nonAdaptive?.question));

      setTimer(parseInt(nonAdaptive.question?.Duration * 60));
      switch (true) {
        case !!nonAdaptive?.question?.code?.Python?.defaultCode:
          setUserLang("Python");
          setUserCode(nonAdaptive.question?.code?.Python?.defaultCode);
          // dispatch(setCode(nonAdaptive.question?.code?.Python?.defaultCode));
          dispatch(setCodeLang("Python"));
          break;
        case !!nonAdaptive.question?.code?.Java?.defaultCode:
          //console.log(nonAdaptive.question, "isJava");
          setUserLang("Java");
          setUserCode(nonAdaptive?.question?.code?.Java?.defaultCode);
          setCoding(nonAdaptive?.question);

          // dispatch(setCode(nonAdaptive.question?.code?.Java?.defaultCode));
          dispatch(setCodeLang("Java"));
          break;
        case !!nonAdaptive?.question?.code?.C?.defaultCode:
          setUserLang("C");
          setUserCode(nonAdaptive.question?.code?.C?.defaultCode);
          // dispatch(setCode(nonAdaptive.question?.code?.C?.defaultCode));
          dispatch(setCodeLang("C"));
          break;
        case !!nonAdaptive?.question?.code?.Cpp?.defaultCode:
          setUserLang("Cpp");
          setUserCode(nonAdaptive.question?.code?.Cpp?.defaultCode);

          // dispatch(setCode(nonAdaptive.question?.code?.Cpp?.defaultCode));
          dispatch(setCodeLang("Cpp"));
          break;

        default:
          //console.log(nonAdaptive.question?.code, "isPy");
          setUserLang("Python");
          setUserCode(nonAdaptive.question?.code?.Python?.defaultCode);

          dispatch(setCodeLang("Python"));
      }
    }
  }, [question, nonAdaptive.question]);

  // -----------------------------------------------------------------------------------------------------------

  const handleAdaptive = async () => {
    if (answerIndex < 0 || answerIndex > 3 || answerIndex === null) {
      toast.error("Please select an answer");
      return;
    }
    setAnswerIndex(-1);
    try {
      const res = await dispatch(
        sendResponse({ testId: id, response: answerIndex, timeTaken })
      );
      if (!res.data.nextQuestion) {
        setSubmit(true);
      }
      setNumberCount((prev) => prev + 1);
    } catch (error) {
      console.error("Error in handleAdaptive:", error);
    }
  };

  const handleAdaptiveSkip = async () => {
    setAnswerIndex(-1);
    try {
      const res = await dispatch(
        sendResponse({ testId: id, response: -1, timeTaken })
      );
      if (!res.data.nextQuestion) {
        setSubmit(true);
      }
    } catch (error) {
      console.error("Error in handleAdaptiveSkip:", error);
    }
  };

  const handleNonAdaptive = async () => {
    let response = handleResponse("submit");
    if (response === undefined) {
      return;
    }
    if (!canSubmit && nonAdaptive.question.codeQuestion) {
      dispatch(getLog({ testId: id, question: nonAdaptive.question._id }));
      setIsCompiled(true);
      return;
    } else {
      setAnswerIndex(-1);
      setUserInput("");
      setNumberCount((prev) => prev + 1);
      setFindAnswer([]);
      setEssay("");
      setLogs([]);
      setVideo({ long: [""], short: [""], mcq: [-1] });
      try {
        const res = await dispatch(
          sendResponseNonAdaptive({ testId: id, response, timeTaken })
        );
        if (res?.payload?.nextQuestion) {
          setSubmit(true);
          setUserInput(res?.payload?.nextQuestion?.testcase[0]?.input);
          setLogs([]);
          // setUserCode(res.payload.nextQuestion.code[userLang].defaultCode);
        }

      } catch (error) {
        console.error("Error in handleNonAdaptive:", error);
      } finally {
        return;
      }
    }
  };

  const handleNonAdaptiveSkip = async () => {
    let response = handleResponse("skip");
    if (response === undefined) {
      return;
    }
    setFindAnswer([]);
    setLogs([]);
    setUserInput("");
    setEssay("");
    setVideo({ long: [""], short: [""], mcq: [-1] });
    setAnswerIndex(-1);
    try {
      const res = await dispatch(
        sendResponseNonAdaptive({ testId: id, response, timeTaken })
      );
      if (res.data?.nextQuestion) {
        setUserInput((prev) => {
          try {
            if (nonAdaptive?.question?.testcase?.length > 0 && !prev) {
              return nonAdaptive?.question.testcase[0].input;
            }
            return prev;

          } catch (error) {
            return prev;
          } finally { setLogs([]); }
        });
        setUserCode(res?.payload?.nextQuestion?.code[userLang]?.defaultCode);
        setSubmit(true);
      }
    } catch (error) {
      console.error("Error in handleNonAdaptiveSkip:", error);
    }
  };

  useEffect(() => {
    if (canSubmit) {
      handleNonAdaptive();
      setCoding({
        code: {
          Java: { answerCode: "" },
          Python: { answerCode: "" },
          C: { answerCode: "" },
          Cpp: { answerCode: "" },
        },
        codeLanguage: "",
        totalTestCases: 0,
        totalPassedTestCases: 0,
        testcase: [],
      });
      setCanSubmit(false);
      setIsCompiled(false);
    }
  }, [canSubmit]);

  useEffect(() => {
    const executeTestEnd = async () => {

      try {

        await dispatch(setSubmitTest(false));
        await dispatch(
          endTest({ timeTaken, testId: id, isAdaptive: isAdaptive === "true", endType: { type: "manual", description: "User manually submitted the test" } })
        );
        // navigate("/student/test")
        setSubmitLoading(false);
      } catch (error) {
        console.log(error)
      }
    };

    if (submitTest) {
      setSubmitLoading(true);
      let response = handleResponse("submit");
      if (isAdaptive === "true") {
        if (response === undefined) {
          handleAdaptiveSkip().then(executeTestEnd);
          navigate(`/test/start/end/${id}`);
        } else {
          handleAdaptive().then(executeTestEnd);
          navigate(`/test/start/end/${id}`);
        }
      } else {

        if (nonAdaptive?.question?.codeQuestion) {
          dispatch(compileCode({ answer: code, question: nonAdaptive.question, userLang })).then(async (res) => {
            await dispatch(
              sendResponseNonAdaptive({ testId: id, response: res.payload, timeTaken })
            )
            await dispatch(
              endTest({ timeTaken, testId: id, isAdaptive: isAdaptive === "true", endType: { type: "manual", description: "User manually submitted the test" } })
            );
            navigate(`/test/start/end/${id}`);
          });
          return;
        }
        if (response === undefined) {
          handleNonAdaptiveSkip().then(async () => {
            await executeTestEnd()
          });
          navigate(`/test/start/end/${id}`);
        } else {
          handleNonAdaptive().then(async () => {
            await executeTestEnd()
            navigate(`/test/start/end/${id}`);
          });
        }
      }
    }
  }, [submitTest, canSubmit]);

  useEffect(() => {
    if (!runTestLoading && autoSubmit) {
      const res = handleResponse("timeout");
      dispatch(
        sendResponseNonAdaptive({
          testId: id,
          response: res,
          timeTaken: timeTaken,
        })
      ).then((res) => {
        // console.log(res)
        setAutoSubmit(false)
        setCoding(res.payload.nextQuestion)
        setAnswerIndex(-1);
        setFindAnswer([]);
        setLogs([]);
        setEssay("");
        setVideo({
          long: [""],
          short: [""],
          mcq: [-1],
        });
      });
    }
  }, [runTestLoading])

  let totalQ = localStorage.getItem("totalQuestions");
  let totalTopics = JSON.parse(localStorage.getItem("topicNames"))?.length;

  // //console.log("topic", currentTopicIndex, totalTopics - 1);
  // //console.log("totalQ", localStorage.getItem("topicNames"), count, totalQ);

  return (
    <StudentTestLayout>
      <ConnectionStatus testId={id} timeTaken={timeTaken} />
      <>
        {submitLoading && <SubmitTestLoader />}
        <div className="flex w-full mx-auto gap-2 flex-col h-full">
          {showConfirmReset && (
            <ConfirmPoP
              title="Are you sure you want to reset the question? All changes will be lost."
              handleConfirm={() => {
                setCoding(JSON.parse(localStorage.getItem("question")));
                setShowConfirmReset(false);
              }}
              handleCancel={() => setShowConfirmReset(false)}
            />
          )}
          {showPopUp && (
            <ConfirmPoP
              title="Are you sure you want to submit the test?"
              handleConfirm={async () => {
                await dispatch(endTest(
                  {
                    timeTaken,
                    testId: id,
                    isAdaptive,
                    endType: { type: "manual", description: "User manually submitted the test" }
                  }
                ));
              }}
              handleCancel={() => setShowPopUp(false)}
            />
          )}
          {showFullscreenPrompt && (
            <FullscreenPrompt
              handleEnterFullscreen={handleEnterFullscreen}
              handleCancel={handleCancelFullscreen}
            />
          )}
          <div className="flex justify-between items-center ">
            <div className="text">
              <h2 className="text-2xl leading-[36px] font-bold self-center font-dmSans first-letter:uppercase mb-2">
                {question && isAdaptive === "true"
                  ? topic?.Heading || topicName
                  : nonAdaptive?.topic?.Heading || nonAdaptive?.topic}
              </h2>
              <p className="text-[#fff] text-sm uppercase bg-green-500 p-1 rounded-md text-center">
                {question && isAdaptive === "true"
                  ? topic?.Type
                  : formatType(nonAdaptive.topicType) || formatType(topic.Type)}
              </p>
            </div>

            <button
              className="self-center w-32  justify-center flex text-blud py-2 px-4 rounded-md font-bold gap-2 bg-white"
            // onClick={() => navigate(-1)}
            >
              <img
                src="/images/icons/CombinedShape.png"
                alt=""
                className="self-center w-6 h-6"
              />
              <p className="text-lg font-bold self-center text-[#E45B39] ">
                {timer > 0 && timerText}
              </p>
            </button>
          </div>

          <div className="flex  justify-between items-center">
            {nonAdaptive.question.codeQuestion && (
              <h2 className="text-2xl font-bold w-full">
                {" "}
                Q{nonAdaptive?.count + ". "}
                {nonAdaptive?.question?.Title}
              </h2>
            )}
            {(nonAdaptive.question.codeQuestion && !nonAdaptive.isLast && !NEXT_QUESTION_LOADING && !runTestLoading) && (
              <div className="flex gap-4">
                {<button
                  className=" bg-white border borde-2 border-blued text-blued py-2 px-12 rounded-md disabled:bg-opacity-70 disabled:cursor-not-allowed"
                  disabled={NEXT_QUESTION_LOADING}
                  onClick={() => {
                    if (!NEXT_QUESTION_LOADING) {
                      isAdaptive === "true"
                        ? handleAdaptiveSkip()
                        : handleNonAdaptiveSkip();
                    }
                  }}
                >
                  Skip
                </button>}
                {(!NEXT_QUESTION_LOADING && !runTestLoading) && <button
                  className="bg-[#00875A] text-white py-2 px-12 rounded-md flex"
                  onClick={() => {

                    if (!NEXT_QUESTION_LOADING) {
                      isAdaptive === "true"
                        ? handleAdaptive()
                        : handleNonAdaptive();
                    }

                  }}
                >
                  {NEXT_QUESTION_LOADING ? "Saving..." : " Next"}
                  {NEXT_QUESTION_LOADING && <Loader />}
                </button>}
                <button
                  className="bg-[#00875A] text-white py-2 px-12 w-full rounded-md flex items-center gap-2 transition-colors duration-300 hover:bg-[#006F48]"
                  onClick={() => {
                    setShowConfirmReset(true);
                  }}
                >
                  <BiReset className="text-lg" /> {/* Reset icon */}
                  Reset
                </button>
              </div>
            )}
          </div>

          <div className="questions flex flex-col items-between mt-2 ">
            {/* <h3 className="text-xl font-bold text-[#3E3E3E] ">{numberCount}</h3> */}
            {/* {//console.log(isAdaptive === "true", nonAdaptive.question)} */}
            {isAdaptive === "true" ? (
              <Questions
                count={count}
                question={question}
                answerIndex={answerIndex}
                setAnswerIndex={setAnswerIndex}
                loading={NEXT_QUESTION_LOADING}
              />
            ) : (
              (() => {
                if (
                  nonAdaptive?.question?.Options &&
                  !nonAdaptive?.question?.questions &&
                  !nonAdaptive.question.codeQuestion &&
                  !nonAdaptive.question.videoFile
                ) {
                  return (
                    <QuestionsNonAdaptive
                      count={nonAdaptive?.count}
                      question={nonAdaptive?.question}
                      answerIndex={answerIndex}
                      setAnswerIndex={setAnswerIndex}
                      loading={NEXT_QUESTION_LOADING}
                    />
                  );
                } else if (
                  nonAdaptive?.question?.questions &&
                  nonAdaptive?.question.Title &&
                  !nonAdaptive.question.Options &&
                  !nonAdaptive.question.codeQuestion &&
                  !nonAdaptive.question.videoFile
                ) {
                  return (
                    <FindAns
                      count={nonAdaptive?.count}
                      question={nonAdaptive?.question}
                      setAnswer={setFindAnswer}
                      answer={findAns}
                      loading={NEXT_QUESTION_LOADING}
                    />
                  );
                } else if (
                  nonAdaptive?.question.Title &&
                  !nonAdaptive.question.Options &&
                  !nonAdaptive.question.questions &&
                  !nonAdaptive.question.codeQuestion &&
                  !nonAdaptive.question.videoFile
                ) {
                  return (
                    <Essay
                      count={nonAdaptive?.count}
                      question={nonAdaptive?.question}
                      setAnswer={setEssay}
                      answer={essay}
                      loading={NEXT_QUESTION_LOADING}
                    />
                  );
                } else if (
                  nonAdaptive.question.codeQuestion &&
                  !nonAdaptive.question.Options &&
                  !nonAdaptive.question.questions &&
                  !nonAdaptive.question.videoFile
                ) {
                  //handleRunCode()
                  return (
                    <Code
                      timer={timer}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      logsState={logsState}
                      setLogs={setLogs}
                      submission={submission}
                      setSubmission={setSubmission}
                      testId={id}
                      userLang={userLang}
                      setUserLang={setUserLang}
                      question={nonAdaptive.question}
                      count={nonAdaptive.count}
                      userCode={userCode}
                      setUserCode={setUserCode}
                      setAnswer={setCoding}
                      answer={coding}
                      isCompiled={isCompiled}
                      setIsCompiled={setIsCompiled}
                      setCanSubmit={setCanSubmit}
                      canSubmit={canSubmit}
                      isLoading={NEXT_QUESTION_LOADING}
                      runTestLoading={runTestLoading}
                      setRunTestLoading={setRunTestLoading}
                    />
                  );
                  // Handle(compiler)
                } else {
                  return (
                    <Video
                      count={nonAdaptive.count}
                      question={nonAdaptive.question}
                      setAnswer={setVideo}
                      answer={video}
                      loading={NEXT_QUESTION_LOADING}
                    />
                  );
                  // Handle video question
                }
              })()
            )}
          </div>

          {
            // WORKING EXCEPT  ONE TOPIC[ONE QUESTION]

            (isAdaptive === "true" || !nonAdaptive?.question?.codeQuestion) &&
            (isAdaptive === "true" ? !isLast : !nonAdaptive?.isLast) && (
              <div className="flex mt-8 justify-end gap-4 mr-4 pb-4">
                {!NEXT_QUESTION_LOADING && <button
                  className=" bg-white border borde-2 border-blued text-blued py-2 px-12 rounded-md disabled:bg-opacity-70 disabled:cursor-not-allowed"
                  disabled={NEXT_QUESTION_LOADING}
                  onClick={() => {
                    if (!NEXT_QUESTION_LOADING) {
                      isAdaptive === "true"
                        ? handleAdaptiveSkip()
                        : handleNonAdaptiveSkip();
                    }
                  }}
                >
                  Skip
                </button>}
                <button
                  className="bg-[#00875A] text-white py-2 px-12 rounded-md flex"
                  onClick={() => {
                    if (!NEXT_QUESTION_LOADING) {
                      isAdaptive === "true"
                        ? handleAdaptive()
                        : handleNonAdaptive();
                    }
                  }}
                >
                  {NEXT_QUESTION_LOADING ? "Saving..." : "Next"}
                  {NEXT_QUESTION_LOADING && <Loader />}
                </button>


              </div>
            )




          }
        </div>
      </>
    </StudentTestLayout>
  );
};

export default TestHome;
