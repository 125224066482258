import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssessmentById,
  startTest,
} from "../../../redux/student/test/thunks/assessment";
import { toast } from "react-hot-toast";
import { FaAngleLeft } from "react-icons/fa";

const Instruction = () => {
  // useTranslate();
  const navigate = useNavigate();
  const { currentAssessment, START_TEST_LOADING } = useSelector(
    (state) => state.studentTest
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isAdaptive = searchParams.get("isAdaptive");
  const assessment = currentAssessment?.assessment;

  const startDate = new Date(assessment?.startDate); // Convert startDate to Date object
  const endDate = new Date(assessment?.endDate); // Convert endDate to Date object
  const now = new Date(); // Get current date and time

  const isTestActive = now >= startDate && now <= endDate; // Check if current time is within the test period
  const testStatusMessage =
    now < startDate
      ? "The test has not started yet. Please wait until the start time."
      : now > endDate
      ? "The test has ended. You can no longer attempt it."
      : null;

  const startAssess = async () => {
    try {
      if (assessment?.config?.isCameraRequired) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false,
          });
          // Stop the video stream immediately after getting access
          stream.getTracks().forEach((track) => track.stop());
          toast.success("Camera access granted. Test starting...");
        } catch (error) {
          console.error("Camera access error:", error);
          toast.error("Camera access is required to start the test.");
          return;
        }
      }

      console.log("Starting test...");
      console.log(assessment?.config?.isCameraRequired);

      const result = await dispatch(
        startTest({
          testId: id,
          isAdaptive: searchParams.get("isAdaptive") === "true",
        })
      ).unwrap();

      if (result) {
        toast.success("Test started successfully");
      }
    } catch (error) {
      console.error("Failed to start assessment:", error);
      toast.error(
        "An error occurred while starting the test. Please try again."
      );
      navigate(-1);
    }
  };

  useEffect(() => {
    dispatch(getAssessmentById(id));
  }, [dispatch, id]);

  const questionTypes = {
    mcq: 0,
    compiler: 0,
    essay: 0,
    findAnswer: 0,
    video: 0,
  };

  let adaptiveNumber = 0;
  assessment?.topics?.forEach((topic) => {
    adaptiveNumber += topic?.totalL1Question || 0;
    switch (topic?.Type) {
      case "mcq":
        questionTypes.mcq +=
          topic.totalL1Question ||
          topic.totalL2Question ||
          topic.totalL3Question ||
          0;
        // questionTypes.mcq += topic.questions?.length || 0;
        break;
      case "compiler":
        questionTypes.compiler += topic.compiler?.length || 0;
        break;
      case "findAnswer":
        questionTypes.findAnswer += topic.findAnswers?.length || 0;
        break;
      case "essay":
        questionTypes.essay += topic.essay?.length || 0;
        break;
      case "video":
        questionTypes.video += topic.video?.length || 0;
        break;
      default:
        break;
    }
  });

  const numberCount =
    questionTypes.mcq +
    questionTypes.compiler +
    questionTypes.essay +
    questionTypes.findAnswer +
    questionTypes.video;

  console.log("Current Assessment Data:", currentAssessment.assessment);
  console.log(
    "Keys in Current Assessment:",
    Object.keys(currentAssessment || {})
  );
  console.log(
    "Size of Current Assessment (JSON):",
    JSON.stringify(currentAssessment)?.length,
    "bytes"
  );

  return (
    <>
      <button
        className="bg-white border self-center rounded-md p-2 hover:shadow-md transition-shadow duration-300 hover:border-gray-500"
        onClick={() => navigate(-1)}
      >
        <FaAngleLeft className="h-5 w-5" />
      </button>

      <div className="bg-white rounded-md p-6 sm:p-8 shadow-lg border border-gray-200">
        {/* Container */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* LEFT COLUMN: General Policies & Instructions */}
          <div className="lg:w-1/2 space-y-6">
            <h2 className="text-2xl font-semibold mb-4 pb-2 border-b">
              Test Guidelines
            </h2>
            {/* Proctoring Settings */}
            <div className="rounded-lg border-l-4 border-blue-300 bg-blue-50 p-4 shadow-sm">
              <h3 className="font-semibold text-blue-700 mb-2 flex items-center gap-2">
                <span>🔒</span> Proctoring Settings
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  <span className="flex">
                    Full-screen mode is mandatory, exiting will log you out.
                  </span>
                </li>
                <li className="pl-1">
                  <span className="flex">
                    All popups, tab switches, or window changes will terminate
                    your session.
                  </span>
                </li>
                <li className="pl-1">
                  <span className="flex">
                    Camera access may be required for identity verification and
                    monitoring.
                  </span>
                </li>
              </ul>
            </div>
            {/* Plagiarism Policy */}
            <div className="rounded-lg border-l-4 border-red-300 bg-red-50 p-4 shadow-sm">
              <h3 className="font-semibold text-red-700 mb-2 flex items-center gap-2">
                <span>⛔</span> Plagiarism Policy
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  Copy-paste is disabled in the problem statement and code
                  editor.
                </li>
                <li className="pl-1">
                  Sharing answers or using external online tools is strictly
                  prohibited.
                </li>
                <li className="pl-1">
                  Violations can lead to permanent account suspension or
                  blacklisting.
                </li>
              </ul>
            </div>
            {/* Test Environment */}
            <div className="rounded-lg border-l-4 border-green-300 bg-green-50 p-4 shadow-sm">
              <h3 className="font-semibold text-green-700 mb-2 flex items-center gap-2">
                <span>🌐</span> Test Environment
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  Use a stable internet connection (at least 40 Mbps
                  recommended).
                </li>
                <li>Avoid mobile tethering or unreliable networks.</li>
                <li className="pl-1">
                  Close all other programs and notifications before starting.
                </li>
                <li className="pl-1">
                  Prefer a private/incognito window to avoid browser extension
                  interference.
                </li>
                <li className="pl-1">
                  You are not allowed to modify driver code, doing so will lead
                  to permanent rejection
                </li>
              </ul>
            </div>
            {/* During the Test */}
            <div className="rounded-lg border-l-4 border-yellow-300 bg-yellow-50 p-4 shadow-sm">
              <h3 className="font-semibold text-yellow-700 mb-2 flex items-center gap-2">
                <span>⏱️</span> During the Test
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  Maintain focus on the test window, tab switching may trigger
                  warnings or termination.
                </li>
                <li className="pl-1">
                  Stay within the camera frame (if camera proctoring is
                  enabled).
                </li>
                <li className="pl-1">
                  Watch your timer; the test will auto-submit once time is up.
                </li>
                <li className="pl-1">
                  Avoid any external help or collaboration to prevent
                  disqualification.
                </li>
              </ul>
            </div>
            {/* Violation & Auto-Submission */}
            <div className="rounded-lg border-l-4 border-indigo-300 bg-indigo-50 p-4 shadow-sm">
              <h3 className="font-semibold text-indigo-700 mb-2 flex items-center gap-2">
                <span>⚠️</span> Violation & Auto-Submission
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  Any unauthorized activity (e.g., tab switching, exiting
                  full-screen) may lead to immediate submission.
                </li>
                <li className="pl-1">
                  Major rule violations, such as external help or cheating, can
                  result in instant disqualification.
                </li>
              </ul>
            </div>
            {/* Final Reminders */}
            <div className="rounded-lg border-l-4 border-purple-300 bg-purple-50 p-4 shadow-sm">
              <h3 className="font-semibold text-purple-700 mb-2 flex items-center gap-2">
                <span>✅</span> Final Reminders
              </h3>
              <ul className="text-gray-700 list-disc ml-4 space-y-1">
                <li className="pl-1">
                  Contact support immediately if you face technical issues.
                </li>
                <li className="pl-1">
                  Refresh or closing the window without instruction may lock you
                  out.
                </li>
                <li className="pl-1">
                  By starting the test, you agree to abide by all these rules
                  and maintain academic integrity.
                </li>
              </ul>
            </div>
          </div>
          {/* RIGHT COLUMN: Test Overview & Start Button */}
          <div className="lg:w-1/2 flex flex-col gap-6 mt-9">
            {/* Overview Section */}
            <div className="rounded-lg p-4 shadow-sm border border-gray-300 hover:bg-gray-50 transition-colors">
              <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                Test Overview
              </h2>
              <p className="mb-2">
                <strong className="block mb-1">Number of Questions:</strong>
                <span className="text-gray-600">
                  {isAdaptive === "false" ? numberCount : adaptiveNumber}
                </span>
              </p>
              <p className="mb-2">
                <strong className="block mb-1">Types of Questions:</strong>
                <span className="text-gray-600">
                  {isAdaptive === "false"
                    ? Object.keys(questionTypes)
                        .filter((key) => questionTypes[key])
                        .map((key, index) => (
                          <span key={key}>
                            {index > 0 && ", "}
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </span>
                        ))
                    : "Mcq"}
                </span>
              </p>
            </div>
            {/* Adaptive Details */}
            {isAdaptive === "true" && (
              <div className="rounded-lg p-4 shadow-sm border border-gray-300 hover:bg-gray-50 transition-colors">
                <h2 className="text-lg font-semibold mb-3 border-b pb-2">
                  Adaptive Test Explanation
                </h2>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start gap-2">
                    <span className="h-1.5 w-1.5 rounded-full border  mt-2"></span>
                    <span>
                      There are three levels of questions: L1, L2, and L3.
                    </span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="h-1.5 w-1.5 rounded-full border  mt-2"></span>
                    <span>
                      You must correctly answer at least one-third of the
                      questions in each level to move to the next.
                    </span>
                  </li>
                  <li className="mt-4 bg-gray-100  rounded-lg p-4 list-none">
                    <span className="font-semibold block mb-2">Example:</span>
                    <p className="text-gray-600 text-sm leading-relaxed">
                      If there are a total of 5 questions, you need to answer 2
                      correctly at L1 to move to L2, and then 2 correctly at L2
                      to reach L3. If you fail to answer 2 correctly at L1, you
                      remain in L1 for all 5 questions.
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {/* Non-Adaptive Time Info */}
            {isAdaptive === "false" && (
              <div className="rounded-lg  p-4 shadow-sm border border-gray-300 hover:bg-gray-50 transition-colors">
                <h2 className="text-lg font-semibold mb-3 border-b pb-2">
                  Time Allocation
                </h2>
                <p className="text-gray-700">
                  <strong>Total Test Duration:</strong>{" "}
                  <span className="text-gray-600">
                    {assessment?.totalTime} mins
                  </span>
                </p>
              </div>
            )}
            {/* Additional Config Instructions (If Any) */}
            {assessment?.config?.instructions?.length > 0 && (
              <div className="rounded-lg p-4 shadow-sm border border-gray-300 space-y-3 hover:bg-gray-50 transition-colors">
                <h2 className="text-lg font-semibold mb-3 border-b pb-2">
                  Additional Instructions
                </h2>
                {assessment?.config?.instructions?.map((item, index) => (
                  <div key={index} className="mb-2 last:mb-0">
                    <p>
                      <strong>{item?.title}:</strong>{" "}
                      <span className="text-gray-600">{item?.description}</span>
                    </p>
                  </div>
                ))}

                <div className="mt-3 py-2  rounded-md text-sm">
                  <p>
                    <strong className="block mb-1">Start Date:</strong>

                    <span className="text-gray-600">
                      {new Date(assessment?.startDate)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true, // Ensures AM/PM format
                        })
                        .replace(",", "")}{" "}
                      {/* Removes comma */}
                    </span>
                  </p>
                  <p>
                    <strong className="block mb-1">End Date:</strong>{" "}
                    <span className="text-gray-600">
                      {new Date(assessment?.endDate)
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true, // Ensures AM/PM format
                        })
                        .replace(",", "")}{" "}
                      {/* Removes comma */}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {/* Start Button */}
            <div className="pt-4">
              {/* {assessment && (
              <button
                onClick={startAssess}
                disabled={START_TEST_LOADING}
                className="bg-accent text-white uppercase px-8 py-3 rounded-lg hover:shadow-lg transition-shadow duration-200 inline-flex items-center justify-center gap-2 disabled:opacity-70 hover:bg-secondary"
              >
                {START_TEST_LOADING ? "Starting..." : "Start Test"}
                {START_TEST_LOADING && <Loader />}
              </button>
            )} */}

              <button
                onClick={startAssess}
                disabled={!isTestActive || START_TEST_LOADING}
                className="bg-accent text-white uppercase px-8 py-3 rounded-lg hover:shadow-lg transition-shadow duration-200 inline-flex items-center justify-center gap-2 disabled:opacity-70 hover:bg-secondary"
              >
                {START_TEST_LOADING ? "Starting..." : "Start Test"}
                {START_TEST_LOADING && <Loader />}
              </button>

              {/* Show test status message if test is not active */}
              {!isTestActive && (
                <p className="text-red-500 text-sm mt-2">{testStatusMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instruction;
