import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { forgotPassword } from "../../../redux/student/auth/studentAuthSlice";
import Layout from "./Layout";
import InputField from "../../../components/InputField";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(forgotPassword({ email }));
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Password reset link has been sent to your email.");
        setEmail("");
      } else {
        toast.error("Invalid Email");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <form
        className="w-full max-w-xs mx-auto text-center space-y-5"
        onSubmit={handleSubmit}
      >
        <img src="/images/logo.png" alt="Logo" className="mx-auto mb-4" />
        <h2 className="font-bold text-2xl">Forgot Password</h2>
        <p className="text-sm text-gray-500 mt-2">
          Enter your email address, and we'll send you a link to reset your
        </p>
        <InputField
          type="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          type="submit"
          className={`btn bg-accent text-white w-full mt-4 flex items-center justify-center rounded-md shadow-md ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={loading || !email}
        >
          {loading ? "Sending..." : "Send Link"}
        </button>
      </form>
    </Layout>
  );
};

export default ForgotPassword;
