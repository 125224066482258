import React from "react";
// import { useEffect } from "react";
// import { getTest } from "../../../../redux/collage/test/testSlice";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import convertDateFormat, {
  convertToDDMM,
} from "../../../../util/getDateFromat";

const Info = ({ user, assessment }) => {
  const uniqueStudentResponses = new Set(
    assessment?.studentResponses?.map((response) => response.studentId)
  );

  const totalUniqueStudentResponses = uniqueStudentResponses.size || 0;

  return (
    <div className="flex justify-between bg-[#8F92A1]   bg-opacity-5 p-5 mb-1">
      <div className="flex items-center gap-7">
        <div className="w-14  flex object-cover rounded-md  self-center  p-2 h-16 bg-white">
          <img
            src={user?.avatar?.url || "/images/companyLogo.png"}
            alt="college logo"
            className="self-center"
          />
        </div>

        <div className="font-dmSans flex items-center">
          <h2 className="text-lg font-bold mb-1 ">{assessment?.name}</h2>

          {/* <h2 className="text-xs mb-2">{}</h2> */}
          {/* <h2 className="text-[#7D7D7D] text-xs">Available Jobs</h2> */}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-14 text-center font-dmSans">
        <div className="self-center">
          <h2 className="text-xs font-bold text-gray-400 mb-2">TIME PERIOD</h2>

          <h2 className="text-sm font-bold min-h-10 ">{assessment?.totalTime} mins</h2>
        </div>

        <div className="self-center ">
          <h2 className="text-xs font-bold text-gray-400 mb-2 ">TIMELINE</h2>
          <h2 className="text-sm font-bold min-h-10  ">
            {assessment && convertToDDMM(assessment.startDate)}-{" "}
            {assessment &&
              convertToDDMM(
                assessment.endDate ? assessment.endDate : assessment.startDate
              )}
          </h2>
        </div>

        <div className="self-center">
          <h2 className="text-xs font-bold text-gray-400 mb-2 ">
            STUDENTS APPEARED
          </h2>

          <h2 className="text-sm font-bold min-h-10 ">
            {assessment?.studentResponses?.length || 0}
          </h2>
        </div>

        <div className="self-center">
          <h2 className="text-xs font-bold text-gray-400 mb-2">ATTEMPTS</h2>

          <h2 className="text-sm font-bold min-h-10 ">{assessment?.totalAttempts}</h2>
        </div>
      </div>
    </div>
  );
};

export default Info;
