import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiBell } from "react-icons/fi";
import { useSelector } from "react-redux";
import { FaCoins } from "react-icons/fa";
import useTranslate from "../../hooks/useTranslate";
import GoogleTranslate from "../GoogleTranslate";

const Navbar = (props) => {
  const navigate = useNavigate();
  const currentLanguage = useTranslate();

  const goToProfile = () => {
    navigate("/student/profile"); // Use navigate function to navigate to desired URL
  };
  const userDetails = useSelector((state) => state.studentAuth);

  return (
    <div className="border border-y-1 sorder border-gray-400 bg-white w-full z-[9999] m-0 fixed top-0">
      <div className="navbar flex justify-between ">
        {/* left */}

        <img src="/images/logo.png" alt="" width="180px" />

        {/* right */}
        <div className="flex gap-4">
          {/* <button className="border-2 border-[#D9E1E7]  text-[#D9E1E7] rounded-lg p-1 px-2 relative">
            <FiBell className="text-lg" />{" "}
            <div className="rounded-full h-2 w-2 bg-lightBlue  absolute top-1 right-2"></div>
          </button> */}

          {/* noifiaction */}
          {/* <GoogleTranslate currentLanguage={currentLanguage} /> */}
          <button
            className="border border-[#D9E1E7]  rounded-md p-2 relative flex gap-3 "
            onClick={goToProfile}
          >
            <img
              src={userDetails?.user?.avatar?.url}
              alt=""
              className="h-5 w-5 p-[2px] rounded"
            />{" "}
            <h2 className="text-sm font-bold self-center font-dmSans">
              {userDetails?.user?.FirstName}
            </h2>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
