// Layout.js
import React from "react";

const Layout = ({ children }) => {
  return (
    <section className="min-h-screen bg-background">
      <div className="flex min-h-screen flex-col md:flex-row">
        <div className="hidden md:block md:w-1/2 lg:w-[45%]">
          <div className="sticky top-0 h-screen">
            <img
              className="h-full w-full object-cover"
              src="/images/login-bg.jpg"
              alt="login background"
              priority
            />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 lg:w-[50%] flex-grow items-center justify-center p-6 md:p-8 lg:p-12">
          <div className="w-full">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
