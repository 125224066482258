import React from "react";
import Skeleton from "../../../loaders/Skeleton";
import { setCode } from "../../../../redux/student/test/studentTestSlice";
import { useDispatch } from "react-redux";

const FindAns = ({ question, count, setAnswer, answer, loading }) => {


  const dispatch = useDispatch()
  const handleAnswerChange = (e, index) => {
    const { value } = e.target;
    const updatedAnswers = [...answer];
    updatedAnswers[index] = { studentAnswer: value };
    setAnswer(updatedAnswers);
    dispatch(setCode(updatedAnswers));
    //console.log(answer);
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        question &&
        question.questions && (
          <div className=" bg-[#F8F8F9] rounded-lg justify-between">
            <div className="w-11/12 p-5 flex flex-col gap-2">
              <h2 className="flex gap-3 text-base w-full font-semibold">
                <div>Q{count}.</div>
                <div dangerouslySetInnerHTML={{ __html: question.Title }}></div>
                {/* <div className="self-end">{question.QuestionLevel}</div> */}
              </h2>
              <div className="flex flex-col gap-4">
                {question.questions.map((ques, index) => (
                  <div key={index} className="mb-4">
                    <h3 className="font-bold">{ques.question}</h3>
                    <textarea
                      className=" w-full h-full text-lg bg-gray-100 border-none focus:outline-none mb-3 rounded-lg focus:ring-0 placeholder-gray-400 resize-y"
                      placeholder="Enter Answer Here"
                      name="studentAnswer"
                      value={answer[index]?.studentAnswer || ""}
                      onChange={(e) => handleAnswerChange(e, index)}
                    ></textarea>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default FindAns;
