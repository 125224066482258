import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getIp from "./getIp";
import toast from "react-hot-toast";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
// const REACT_APP_API_URL = "http://localhost:4000";

// //console.log(process.env);

//initial state

const studentState = {
  qr: {
    secret: "",
    code: "",
  },
  status: "",
  error: "",
  Error: [],
  isLoggedIn: false,
  user: null,
  uploadImg: false,
  loggedInUsers: null,
  logoutError: null,
  uploadCV_loading: false,
  updatePersonal_loading: false,
  update_education_loading: false,
  update_skills_loading: false,
  edit_education_loading: false,
  sendMailLoading: false,
  Email: "",
  mail: {
    total: 0,
    attachments: [],
    emailsReceived: [],
    emailsSent: [],
  },
};

export const deleteMail = createAsyncThunk(
  "collageAuth/searchMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.delete(
        `${REACT_APP_API_URL}/api/college/inbox/delete/${data}`,

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchMail = createAsyncThunk(
  "collageAuth/searchMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/college/inbox/search`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendReply = createAsyncThunk(
  "collageAuth/sendMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/college/inbox/reply`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadAttachment = createAsyncThunk(
  "collageAuth/uploadAttachment",
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      data.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const req = await axios.post(
        `${REACT_APP_API_URL}/api/college/inbox/file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendMail = createAsyncThunk(
  "collageAuth/sendMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/college/inbox/sendMail/college`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getMail = createAsyncThunk(
  "collageAuth/getMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${REACT_APP_API_URL}/api/college/inbox/Mail?skip=${data.skip}&limit=${data.limit}`,

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getSecretQr = createAsyncThunk(
  "studentAuth/getSecretQr",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.get(`${REACT_APP_API_URL}/api/student/qr`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        },
      });
      const res = req.data;

      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const selectAuth = createAsyncThunk(
  "studentAuth/selectAuth",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/selectAuth`,
        { type: data.type },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const verifyQr = createAsyncThunk(
  "studentAuth/verifyQr",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/qr`,
        { secret: data.secret, token: data.token },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// verify invitationlink

export const checkInvite = createAsyncThunk(
  "studentAuth/checkInvite",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${REACT_APP_API_URL}/api/student/check/invite?CollegeId=${data.CollegeId}&inviteLink=${data.inviteLink}`,

        {
          headers: {
            "Content-Type": "application/json",
            // "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const registerStudent = createAsyncThunk(
  "studentAuth/registerStudent",
  async (data, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      //console.log("registering", ip);
      // //console.log(process.env);
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/register?CollegeId=${data.CollegeId}&inviteLink=${data.inviteLink}`,
        { ...data, ip },
        { withCredentials: true }
      );
      const res = req.data;
      localStorage.setItem("auth-token", res.token);
      return res.data;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const registerInternxStudent = createAsyncThunk(
  "studentAuth/registerInternxStudent",
  async (data, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/internx-student-register`,
        { ...data, ip },
        { withCredentials: true }
      );

      const res = req.data;
      console.log("Registration Response:", res); // Debugging purpose

      return res; // No token, just return response message
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Registration failed."
      );
    }
  }
);

export const loginStudent = createAsyncThunk(
  "studentAuth/loginStudent",
  async (data, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      //console.log("login", ip);
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/login`,
        { ...data, ip },
        { withCredentials: true }
      );
      const res = req.data;
      localStorage.setItem("auth-token", res.token);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "studentAuth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/password/forgotpassword`,
        data,
        { withCredentials: true }
      );
      const res = req.data;

      return res;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCollege = createAsyncThunk(
  "studentAuth/updateCollege",
  async (data, { rejectWithValue }) => {
    try {
      //console.log("updating", localStorage.getItem("auth-token"));
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/update`,
        data,

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );

      const res = req.data;
      //console.log("should not reject");
      //console.log(res);
      return res.data.college;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStudent = createAsyncThunk(
  "studentAuth/getStudent",
  async (getAll = false, { rejectWithValue }) => {
    try {
      //console.log("getting student", localStorage.getItem("auth-token"));
      const response = await axios.get(
        `${REACT_APP_API_URL}/api/student/me?getAll=${getAll}`,
        {
          withCredentials: true,
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      //console.log(response.data.student);

      return response.data.student;
    } catch (error) {
      //console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAvatar = createAsyncThunk(
  "studentAuth/updateAvatar",
  async (data, { rejectWithValue }) => {
    try {
      //console.log("updating", localStorage.getItem("auth-token"));
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/profile/update/avatar`,
        data,

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      // //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutStudent = createAsyncThunk(
  "studentAuth/logoutStudent",
  async (_, { rejectWithValue }) => {
    try {
      //console.log("logout");
      const req = await axios.get(`${REACT_APP_API_URL}/api/student/logout`, {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
        },
        withCredentials: true,
      });
      const res = req.data;
      localStorage.removeItem("auth-token");
      return res.data;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "studentAuth/resetPassword",

  async (data, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      //console.log("updating", localStorage.getItem("auth-token"));
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/password/reset/${data.token}`,
        { password: data.password, confirmPassword: data.confirmPassword, ip },

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "studentAuth/updatePassword",

  async (data, { rejectWithValue }) => {
    try {
      const ip = await getIp();

      //console.log("updating", localStorage.getItem("auth-token"));
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/password/update`,
        { ...data, ip },

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res.data;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const googleLoginStudent = createAsyncThunk(
  "studentAuth/googleLoginStudent",
  async (accessToken, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      //console.log("google login");
      const req = await axios.post(`${REACT_APP_API_URL}/api/student/login`, {
        googleAccessToken: accessToken,
        ip,
      });
      const res = req.data;
      localStorage.setItem("auth-token", res.token);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const googleRegisterStudent = createAsyncThunk(
  "studentAuth/googleRegisterStudent",
  async ({ accessToken, collegeId, inviteLink }, { rejectWithValue }) => {
    try {
      const ip = await getIp();
      //console.log("google register");
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/register?CollegeId=${collegeId}&inviteLink=${inviteLink}`,
        { googleAccessToken: accessToken, ip: ip }
      );
      const res = req.data;
      //console.log(res, "res.data");
      localStorage.setItem("auth-token", res.token);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLoggedInUsers = createAsyncThunk(
  "studentAuth/getLoggedInUsers",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${REACT_APP_API_URL}/api/student/loggedin/users`,
        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res.loggedInUsers;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutAUser = createAsyncThunk(
  "studentAuth/logoutAUser",
  async (token, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/logout/user/${token}`,
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res.loggedInUsers;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeLoggedOutUser = createAsyncThunk(
  "studentAuth/removeLoggedOutUser",
  async (token, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/remove/logout/user/${token}`,
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res.loggedInUsers;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadCV = createAsyncThunk(
  "studentAuth/uploadCV",
  async (data, { rejectWithValue }) => {
    // not appending the file . it is pres

    let formData = new FormData();
    data.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/cv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePersonal = createAsyncThunk(
  "studentAuth/updatePersonal",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/profile/update/personal`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      //console.log(res);
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateEducation = createAsyncThunk(
  "studentAuth/updateEducation",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/education`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);
// /profile/edit/education
export const editEducation = createAsyncThunk(
  "studentAuth/editEducation",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/edit/education`,
        { Education: data },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const uploadMedia = createAsyncThunk(
  "studentInbox/uploadMedia",
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      data.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/education/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateSkills = createAsyncThunk(
  "studentAuth/updateSkills",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/skill`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateLinks = createAsyncThunk(
  "studentAuth/updateLinks",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/link`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadProfile = createAsyncThunk(
  "studentAuth/uploadProfile",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/profile/update/avatar`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log("catch", error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/api/admin/category/get`
      );
      console.log("API Response:", response.data);
      return response.data.categories || response.data; // Adjust based on your API response structure
    } catch (error) {
      console.error("API Error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch categories"
      );
    }
  }
);

const studentAuthSlice = createSlice({
  name: "studentAuth",
  initialState: studentState,
  reducers: {
    setUploadImg: (state, action) => {
      state.uploadImg = action.payload;
    },
    clearLogoutError: (state, action) => {
      state.logoutError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchMail.fulfilled, (state, action) => {
        const mails = action.payload.map((value) => {
          return { mail: value };
        });
        state.mail.emailsReceived = mails;
        // state.mail = { ...state.mail, attachments: action.payload };
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        let atach = [];
        if (state.mail.attachments) {
          atach = [...state.mail.attachments];
        }
        state.mail = {
          ...state.mail,
          attachments: [...atach, ...action.payload],
        };
      })
      .addCase(uploadAttachment.rejected, (state, action) => {
        toast.error("files not selected");
      })
      .addCase(getMail.fulfilled, (state, action) => {
        if (action.payload.mail) {
          state.mail = { ...action.payload.mail, total: action.payload.total };
        }
      })
      .addCase(sendMail.pending, (state, action) => {
        state.sendMailLoading = true;
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        state.sendMailLoading = false;
        state.mail.attachments = [];
        toast.success("Mail sent successfully");
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.sendMailLoading = false;
        toast.error("Error sending mail");
      })
      .addCase(selectAuth.fulfilled, (state, action) => {
        switch (action.payload.college.authType) {
          case "otp":
            toast.success("authentication switched to text message");
            break;
          case "qr":
            toast.success("authentication switched to auth-app");
            break;
          default:
            toast.success("authentication switched to default");
            break;
        }
      })
      .addCase(verifyQr.fulfilled, (state, action) => {
        toast.success("verified");
        window.location.href = "/student/dashboard";
      })
      .addCase(verifyQr.rejected, (state, action) => {
        toast.error("invalid token");
      })
      .addCase(getSecretQr.fulfilled, (state, action) => {
        state.qr.secret = action.payload.secret.ascii;
        state.qr.code = action.payload.qr;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {})
      .addCase(registerStudent.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(checkInvite.pending, (state, action) => {
        //console.log("pending... checking invitation....")
      })
      .addCase(checkInvite.fulfilled, (state, action) => {
        // toast.success("verified");
        //console.log("Valid Invite")
      })
      .addCase(checkInvite.rejected, (state, action) => {
        // toast.error("invalid invite");
        toast.error(action.payload);
        //console.log(action.payload);
        window.location.href = "/";
        // redirect
      })
      .addCase(registerStudent.fulfilled, (state, action) => {
        // state.status = action.payload
        state.isLoggedIn = true;
        state.user = action.payload;
        // Add any fetched posts to the array
        //console.log("fullfilled");
      })
      .addCase(registerStudent.rejected, (state, action) => {
        //console.log(action.payload);

        // <<<<<<< AnkitaMalik22-ankita-dev
        //   alert(action.payload);
        // =======

        toast.error(action.payload || "Registration failed");
      })
      .addCase(loginStudent.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(loginStudent.fulfilled, (state, action) => {
        // state.status = action.payload
        const { user } = action.payload;
        state.user = user;
        switch (user.authType) {
          case "qr":
            window.location.href = "/securityApp";
            break;
          case "otp":
            window.location.href = "/student/settings/security/secondFA";
            break;
          default:
            state.status = "done";
            state.isLoggedIn = true;
            // window.location.href = "/student/dashboard";
            break;
        }
      })
      .addCase(loginStudent.rejected, (state, action) => {
        //console.log(action.payload);
        state.Error = [action.payload];
      })
      .addCase(updateCollege.pending, (state, action) => {
        // state.status = "loading";
        //console.log("pending");
      })
      .addCase(updateCollege.fulfilled, (state, action) => {
        // state.status = action.payload
        state.user = action.payload;
        window.location.reload(true);
        //console.log("update college fulfilled");
      })
      .addCase(updateCollege.rejected, (state, action) => {
        // //console.log(action.payload);
        // window.alert(action.payload);
        // window.location.reload(true);
        //console.log("rejected update profile");
      })
      .addCase(getStudent.pending, (state, action) => {
        // state.status = "loading";
        //console.log("pending");
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.isLoggedIn = true;
        state.user = action.payload;

        // Add any fetched posts to the array
        //console.log("fullfilled get college");
      })
      .addCase(getStudent.rejected, (state, action) => {
        // state.logoutError = action.payload;
        state.isLoggedIn = false;
        if (!window.location.href.includes("&inviteLink"))
          window.location.href = "/";
        // alert("You are logged out! Please login again");
        else if (
          action.payload.message == "Token is blacklisted. Please login again"
        ) {
          localStorage.removeItem("auth-token");
          state.loggedInUsers = null;
          state.logoutError = action.payload;
        }

        //console.log(action.payload.message);

        // window.alert(action.payload);
      })
      .addCase(updateAvatar.pending, (state, action) => {
        // state.status = "loading";
        //console.log("pending avatar");
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        // state.status = action.payload

        state.status = "success";
        //console.log(action);
        // state.user = action.payload.college;
        state.uploadImg = true;
        state.user.avatar = action.payload.student.avatar;

        // getStudent();
        // Add any fetched posts to the array
        //console.log("fullfilled avatar");
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        if (action.payload.message === "request entity too large") {
          toast.error("File size too large, upload a file less than 50KB");
        }
        //console.log("rejected avatar" + action.payload);

        // window.alert(action.payload);
      })
      .addCase(logoutStudent.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(logoutStudent.fulfilled, (state, action) => {
        // state.status = action.payload

        state.user = null;
        state.isLoggedIn = false;
        localStorage.clear();
        localStorage.setItem("editable", false);
        // Add any fetched posts to the array
        //console.log("fullfilled");
      })
      .addCase(logoutStudent.rejected, (state, action) => {
        //console.log(action.payload);
        // window.alert(action.payload);
      })
      .addCase(updatePassword.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.status = action.payload;
        toast.success("Password Updated");
        // state.status = action.payload
        // state.isLoggedIn = false;
        // state.user = action.payload.user;
        // localStorage.removeItem("auth-token");

        // getStudent();
        // localStorage.setItem("auth-token", action.payload.token);
      })
      .addCase(updatePassword.rejected, (state, action) => {
        alert(action.payload.message);
      })
      .addCase(googleLoginStudent.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending google login");
      })
      .addCase(googleLoginStudent.fulfilled, (state, action) => {
        //console.log("fuillfilled google login");
        state.user = action.payload.user;
        state.status = action.payload;
        localStorage.setItem("auth-token", action.payload.token);
        switch (state.user.authType) {
          case "qr":
            window.location.href = "/securityApp";
            break;
          case "otp":
            window.location.href = "/student/settings/security/secondFA";
            break;
          default:
            state.status = "done";
            state.isLoggedIn = true;
            window.location.href = "/student/profile";
            break;
        }
      })
      .addCase(googleLoginStudent.rejected, (state, action) => {
        // //console.log(action.payload);
        toast.error(action.payload);
        // window.alert(action.payload);
        window.location.href = "/student";
      })
      .addCase(googleRegisterStudent.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(googleRegisterStudent.fulfilled, (state, action) => {
        state.status = action.payload;
        state.isLoggedIn = true;
        state.user = action.payload.user;
        localStorage.setItem("auth-token", action.payload.token);
        window.location.href = "/student/profile";
        // Add any fetched posts to the array
        //console.log("fullfilled");
      })
      .addCase(googleRegisterStudent.rejected, (state, action) => {
        // //console.log(action.payload);

        state.error = action.payload;
        toast.error(action.payload);

        // window.alert(action.payload);
        window.location.href = "/student";
      })
      .addCase(getLoggedInUsers.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(getLoggedInUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.loggedInUsers = action.payload;
        // Add any fetched posts to the array
        //console.log("fullfilled");
      })
      .addCase(getLoggedInUsers.rejected, (state, action) => {
        //console.log(action.payload);
        window.alert(action.payload);
      })
      .addCase(logoutAUser.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(logoutAUser.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload;
        state.loggedInUsers = action.payload;
        //console.log("fullfilled");
      })
      .addCase(logoutAUser.rejected, (state, action) => {
        //console.log(action.payload);

        window.alert(action.payload);
      })
      .addCase(removeLoggedOutUser.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(removeLoggedOutUser.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload;
        state.loggedInUsers = action.payload;
        //console.log("fullfilled");
      })
      .addCase(removeLoggedOutUser.rejected, (state, action) => {
        //console.log(action.payload);
        // getLoggedInUsers();
        if (state.loggedInUsers.length == 0) {
          state.loggedInUsers = null;
          state.logoutError = "No user is logged in";
          window.redirect("/");
        }

        // window.alert(action.payload);
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload;
        // state.loggedInUsers = action.payload;
        //console.log("fullfilled");
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload);
        //console.log(action.payload);
        // getLoggedInUsers();
        // window.alert(action.payload);
      })
      .addCase(uploadCV.pending, (state, action) => {
        state.status = "loading";
        state.uploadCV_loading = true;
        //console.log("pending");
      })
      .addCase(uploadCV.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.user;
        state.uploadCV_loading = false;
        //console.log("fullfilled");
      })
      .addCase(uploadCV.rejected, (state, action) => {
        state.error = action.payload;
        state.uploadCV_loading = false;
        // toast.error(action.payload);
        //console.log(action.payload);
      })
      .addCase(updatePersonal.pending, (state, action) => {
        state.status = "loading";
        state.updatePersonal_loading = true;
        //console.log("pending");
      })
      .addCase(updatePersonal.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload;
        state.updatePersonal_loading = false;
        state.user = action.payload.user;
        // //console.log(action.payload);
        // window.location.reload(true);
        // //console.log("fullfilled");
      })
      .addCase(updatePersonal.rejected, (state, action) => {
        state.error = action.payload;
        state.updatePersonal_loading = false;
        // toast.error(action.payload);
        //console.log(action.payload);
      })
      .addCase(updateEducation.pending, (state, action) => {
        state.status = "loading";
        state.update_education_loading = true;
        //console.log("pending");
      })
      .addCase(updateEducation.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload;
        state.update_education_loading = false;
        state.user = action.payload.user;

        //console.log("fullfilled");
      })
      .addCase(updateEducation.rejected, (state, action) => {
        state.error = action.payload;
        state.update_education_loading = false;
        if (action.payload === "request entity too large") {
          toast.error("File size too large, upload a file less than 50KB");
        } else {
          toast.error(action.payload);
        }
        // toast.error(action.payload);
        // //console.log(action.payload);
      })
      .addCase(updateSkills.pending, (state, action) => {
        state.status = "loading";
        state.update_skills_loading = true;
        //console.log("pending");
      })
      .addCase(updateSkills.fulfilled, (state, action) => {
        state.status = "success";
        // state.user = action.payload.user;
        state.user = action.payload.user;
        state.update_skills_loading = false;
        //console.log("fullfilled");
      })
      .addCase(updateSkills.rejected, (state, action) => {
        state.error = action.payload;
        state.update_skills_loading = false;
        // toast.error(action.payload);
        //console.log(action.payload);
      })
      .addCase(updateLinks.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(updateLinks.fulfilled, (state, action) => {
        state.status = "success";

        state.user = action.payload.user;
        //console.log("fullfilled");
      })
      .addCase(updateLinks.rejected, (state, action) => {
        state.error = action.payload;
        // toast.error(action.payload);
        //console.log(action.payload);
      })
      .addCase(uploadProfile.pending, (state, action) => {
        state.status = "loading";
        //console.log("pending");
      })
      .addCase(uploadProfile.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.student;
        //console.log("fullfilled");
      })
      .addCase(uploadProfile.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload);
        //console.log(action.payload);
      })
      .addCase(editEducation.pending, (state, action) => {
        state.edit_education_loading = true;
        // //console.log("pending");
      })
      .addCase(editEducation.fulfilled, (state, action) => {
        state.edit_education_loading = false;
        // state.user = action.payload.user;
        //console.log("fullfilled");
      })
      .addCase(editEducation.rejected, (state, action) => {
        state.error = action.payload;
        toast.error(action.payload);
        // //console.log(action.payload);
      });
  },
});
export const getSentEmails = (state) => {
  return state.studentAuth.user.emailsSent.map((value) => {
    return { ...value, isChecked: false };
  });
};

export const getInbox = (state) => {
  const received = state.studentAuth.mail.emailsReceived?.map((value) => {
    return { ...value, isChecked: false };
  });
  const sent = state.studentAuth.mail.emailsSent?.map((value) => {
    return { ...value, isChecked: false };
  });
  return { received, sent };
};
//
export const { setUploadImg, clearLogoutError } = studentAuthSlice.actions;
export default studentAuthSlice.reducer;
