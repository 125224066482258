import React, { useEffect, useState } from "react";
import Header from "../../../components/student/test/overview/Header";
import About from "../../../components/student/test/overview/About";
import Info from "../../../components/student/test/overview/Info";
import Description from "../../../components/student/test/overview/Description";
import { useDispatch, useSelector } from "react-redux";
import {
  useBeforeUnload,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getAssessmentById,
  startTest,
} from "../../../redux/student/test/thunks/assessment";
import LoadTest from "../../../Loader";
import useTranslate from "../../../hooks/useTranslate";
import TestInstructionsModal from "../../../components/TestInstructionsModal/TestInstructionsModal";

const TestsOverview = () => {
  const [showInstructions, setShowInstructions] = useState(false);

  const navigate = useNavigate();

  const { currentAssessment, START_TEST_LOADING, GET_TEST_LOADING } =
    useSelector((state) => state.studentTest);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdaptive = searchParams.get("isAdaptive");
  const startAssess = () => {
    // setShowInstructions(true);
    navigate(
      `/test/start/instruction/${id}?isAdaptive=${isAdaptive === "true"}`
    );
  };

  // const handleStartTest = () => {
  //   setShowInstructions(false);
  //   // dispatch(
  //   //   startTest({ testId: id, isAdaptive: isAdaptive === "true" })
  //   // );
  //   navigate(`/test/start/instruction/${id}?isAdaptive=${isAdaptive=== "true"}`);
  // };

  useEffect(() => {
    dispatch(getAssessmentById(id));
  }, []);

  const assessment = currentAssessment?.assessment;
  const handleFullscreen = () => {
    const element = document.documentElement; // or any other element you want to make fullscreen
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };
  return (
    <>
      {!GET_TEST_LOADING ? (
        <>
          <Header />
          <Info user={currentAssessment.sender} assessment={assessment} />

          <About Description={assessment?.description} />

          <div className="bg-[#8F92A1] font-dmSans  bg-opacity-5 p-5 mb-1 rounded-b-xl">
            <div className="flex justify-between gap-2 mb-5 items-center">
              <h2 className="font-bold text-base">Test Description</h2>
              <button
                className="bg-accent text-white rounded-md h-10 font-dmSans px-10  flex items-center"
                onClick={startAssess}
              >
                View the Instructions
              </button>
            </div>
            <Description topics={assessment?.topics} />
          </div>
        </>
      ) : (
        <LoadTest />
      )}

      {/* {showInstructions && (
        <TestInstructionsModal
          onClose={() => setShowInstructions(false)}
          onStart={handleStartTest}
        />
      )} */}
    </>
  );
};

export default TestsOverview;
